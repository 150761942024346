<template>
  <v-card
    class="overflow-hidden mb-5"
  >
    <v-card-title class="d-flex justify-space-between">
      <h4>
        Fichiers
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiFile }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-text-field
        v-model="search"
        label="Rechercher"
        hide-details="auto"
        :append-icon="icons.mdiMagnify"
        clearable
        :clear-icon="icons.mdiCloseCircleOutline"
      >
      </v-text-field>
    </v-card-text>
    <v-card-text>
      <v-treeview
        :items="filteredDirectory"
        activatable
        item-children="files"
        item-key="name"
        open-on-click
        class="mb-6"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon
            v-if="!item.size"
            class="py-3"
          >
            {{ open ? icons.mdiFolderOpen : icons.mdiFolder }}
          </v-icon>
          <v-icon
            v-else
            class="py-3"
          >
            {{ getIcon(item) }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <div v-if="item.size">
            <div>
              <b>{{ item.filename }}</b>
            </div>
            <div>
              [Ajouté le {{ new Date(item.createdAt) | date('dd/MM/yyyy à HH:mm') }}]
            </div>
          </div>
          <div v-else>
            {{ item.name }}
          </div>
        </template>
        <template v-slot:append="{ item }">
          <div v-if="!item.size">
            <v-icon
              @click="editDialog(item)"
            >
              {{ icons.mdiPencil }}
            </v-icon>
            <v-icon
              class="mx-4"
              @click="warningDelete(item)"
            >
              {{ icons.mdiDelete }}
            </v-icon>
            <v-icon
              class="pr-6"
              @click="uploadFile(item)"
            >
              {{ icons.mdiFileUploadOutline }}
            </v-icon>
          </div>
          <div v-else>
            <v-icon
              class="mx-4"
              @click="deleteMedia(item)"
            >
              {{ icons.mdiDelete }}
            </v-icon>
            <v-icon
              class="mx-4"
              @click="downloadFile(item.path, item.filename)"
            >
              {{ icons.mdiDownload }}
            </v-icon>
            <v-icon
              @click="showFile(item)"
            >
              {{ icons.mdiEye }}
            </v-icon>
          </div>
        </template>
      </v-treeview>
      <v-spacer>
      </v-spacer>
      <FichiersTreeAddFolderVue @newFolder="appendFolder"></FichiersTreeAddFolderVue>
      <v-dialog
        v-model="uploadDialog"
        width="700"
      >
        <FichiersUploadDialogVue
          :selected-folder="selectedFolder"
          :mdi-folder-open="icons.mdiFolderOpen"
          @newContent="appendFiles"
        ></FichiersUploadDialogVue>
      </v-dialog>
      <div class="text-center">
        <v-dialog
          v-model="dialogDelete"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Attention
            </v-card-title>

            <v-card-text>
              Vous allez supprimer le dossier <b>{{ selected.name }}</b> ainsi que le(s) <b>{{ selected.files.length }} fichier(s)</b> qu'il contient, êtes-vous sur de vouloir continuer ?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="deleteFolder(selected)"
              >
                Supprimer le dossier
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogEditName"
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Renommer le dossier
            </v-card-title>

            <v-card-text>
              <v-text-field v-model="newname"></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="info"
                outlined
                @click="dialogEditName = false"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                outlined
                @click="renameFolder(selected)"
              >
                Renommer le dossier
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiFolderOpen,
  mdiFolder,
  mdiFile,
  mdiFilePdfBox,
  mdiFileImage,
  mdiFileExcel,
  mdiFileWord,
  mdiDownload,
  mdiFileUploadOutline,
  mdiDelete,
  mdiEye,
} from '@mdi/js'

// import PersonChip from '@/components/PersonChip.vue'
import FichiersTreeAddFolderVue from './FichiersTreeAddFolder.vue'
import FichiersUploadDialogVue from './FichiersUploadDialog.vue'

export default {
  components: {
    FichiersTreeAddFolderVue,
    FichiersUploadDialogVue,
  },

  data() {
    return {
      search: '',
      directory: [],
      uploadDialog: false,
      selectedFolder: {},
      newname: '',
      dialogDelete: false,
      dialogEditName: false,
      selected: {
        name: '',
        files: [],
      },
      icons: {
        mdiPencil,
        mdiFolderOpen,
        mdiFolder,
        mdiFile,
        mdiFilePdfBox,
        mdiFileImage,
        mdiFileExcel,
        mdiFileWord,
        mdiDownload,
        mdiFileUploadOutline,
        mdiDelete,
        mdiEye,
      },
    }
  },
  computed: {
    filteredDirectory() {
      if (!this.search) {
        return this.directory
      }
      // eslint-disable-next-line no-undef
      const directories = structuredClone(this.directory)
      const search = this.search.toLowerCase()

      directories.forEach(dir => {
        dir.files = dir.files.filter(x => x.filename.toLowerCase().indexOf(search) > -1)
      })
      function contains(element) {
        if (element.files.length > 0 || element.name.toLowerCase().indexOf(search) > -1) {
          return true
        }

        return false
      }
      const filteredDir = directories.filter(contains)

      return filteredDir
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/directory/all`)
        .then(dir => {
          this.directory = dir.data
        })
        .catch(error => console.error(error))
    },
    editDialog(item) {
      this.selected = item
      this.newname = item.name
      this.dialogEditName = !this.dialogEditName
    },
    renameFolder(item) {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/directory/${item._id}`, { name: this.newname })
        .then(dir => {
          const foundIndex = this.directory.findIndex(obj => obj._id === dir.data._id)
          const directories = [...this.directory]
          directories[foundIndex].name = dir.data.name
          directories[foundIndex].slug = dir.data.slug
          this.directory = directories
          this.dialogEditName = false
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Dossier mis à jour',
            value: true,
          })
        })
        .catch(error => console.log(error))
    },
    warningDelete(a) {
      this.dialogDelete = true
      this.selected = a
    },
    uploadFile(a) {
      this.uploadDialog = true
      this.selectedFolder = a
    },
    forceFileDownload(response, filename) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    },
    downloadFile(path, filename) {
      const url = `${process.env.VUE_APP_API_URL}/${path}`
      this.$http({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then(response => {
          this.forceFileDownload(response, filename)
        })
        .catch(() => console.log('error occured'))
    },
    showFile(a) {
      window.open(`${process.env.VUE_APP_API_URL}/${a.path}`)
    },
    appendFolder(e) {
      this.directory.push(e)
    },
    appendFiles(payload) {
      const directories = [...this.directory]
      const foundIndex = this.directory.findIndex(x => x._id === this.selectedFolder._id)
      directories[foundIndex].files = directories[foundIndex].files.concat(payload)
      this.directory = directories
      this.uploadDialog = false
    },
    deleteFolder(e) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/directory/${e._id}`)
        .then(() => {
          this.directory = this.directory.filter(folders => folders._id !== e._id)
          this.$store.commit('snackMe', {
            color: 'info',
            text: `Dossier ${e.name} supprimé`,
            value: true,
          })
        })
        .catch(error => console.error(error))
      this.dialogDelete = false
    },
    deleteMedia(item) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/file/${item._id}`)
        .then(response => {
          const matchingDir = this.directory.find(dir => {
            if (dir.files.some(file => file._id === item._id)) {
              return true
            }

            return false
          })
          if (matchingDir) {
            const foundIndex = this.directory.findIndex(e => e._id === matchingDir._id)
            const newDirs = [...this.directory]
            newDirs[foundIndex].files = newDirs[foundIndex].files.filter(x => x._id !== response.data._id)
            this.directory = newDirs
            this.selected = {
              name: '',
              files: [],
            }
          }
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression du fichier',
            value: true,
          })
          console.log(error)
        })
    },
    getPerson(createdBy) {
      const user = this.$store.state.userlist.find(x => x.user._id === createdBy)

      return user.user
    },
    getIcon(file) {
      switch (file.mimetype) {
        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
          return this.icons.mdiFileImage
        case 'application/pdf':
          return this.icons.mdiFilePdfBox
        case '.xls':
        case '.xlsx':
          return this.icons.mdiFileExcel
        case '.doc':
        case '.docx':
          return this.icons.mdiFileWord
        default:
          return this.icons.mdiFile
      }
    },
  },

}
</script>

<style scoped>
</style>
