<template>
  <div>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Ajout de fichier(s) : <v-icon
          class="mx-4"
        >
          {{ mdiFolderOpen }}
        </v-icon>{{ selectedFolder.name }}
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-spacer></v-spacer>

        <v-file-input
          v-model="newFiles"
          chips
          multiple
          label="Ajouter des fichiers"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="newFiles.length <= 0"
          class="me-3 mt-4"
          @click.prevent="uploadFiles"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
    selectedFolder: {
      type: Object,
      default: () => { },
    },
    mdiFolderOpen: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      newFiles: [],
      returnDir: {},
    }
  },
  methods: {
    uploadFiles() {
      const formData = new FormData()
      const FDlen = this.newFiles.length

      // Upload multiple files
      if (FDlen > 1) {
        this.newFiles.forEach(file => {
          formData.append('files', file, file.name)
        })
        this.$http.post(`${process.env.VUE_APP_API_URL}/upload/multiple`, formData)
          .then(response => {
            const organise = {
              directory: this.selectedFolder._id,
              files: response.data,
            }
            this.$http.post(`${process.env.VUE_APP_API_URL}/directory/match-files`, organise)
              .then(res => {
                this.$emit('newContent', res.data)
                this.newFiles = []
              })
          })
          .catch(error => console.error(error))
      } else if (FDlen === 1) {
        // Upload ONE files
        formData.append('file', this.newFiles[0], this.newFiles[0].name)
        this.$http.post(`${process.env.VUE_APP_API_URL}/upload`, formData)
          .then(response => {
            console.log(response)
            const organise = {
              directory: this.selectedFolder._id,
              files: [response.data],
            }
            this.$http.post(`${process.env.VUE_APP_API_URL}/directory/match-files`, organise)
              .then(res => {
                this.$emit('newContent', res.data)
                this.newFiles = []
              })
          })
          .catch(error => console.error(error))
      }
    },
  },
}
</script>

<style>

</style>
