<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row
      class="justify-center text-center"
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="folder"
          :rules="[v => !!v || 'Le nom est requis']"
          label="Nouveau dossier"
          dense
          @keydown.enter.prevent="createFolder"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-btn
          color="success"
          outlined
          @click="createFolder"
        >
          Créer le dossier
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

export default {
  data() {
    return {
      folder: '',
      valid: true,
    }
  },
  methods: {
    createFolder() {
      if (this.$refs.form.validate()) {
        this.$http.post(`${process.env.VUE_APP_API_URL}/directory/new`, { name: this.folder })
          .then(res => {
            this.$store.commit('snackMe', {
              color: 'success',
              text: `Dossier ${res.data.name} créé`,
              value: true,
            })
            this.$emit('newFolder', res.data)
            this.$refs.form.resetValidation()
            this.folder = ''
          })
          .catch(err => {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la création du dossier',
              value: true,
            })
            console.log(err)
          })
      }
    },
  },
}
</script>

<style>

</style>
